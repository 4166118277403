@import "./../../../node_modules/@nucleasa-uxui/ds-portais-externos/styles/style.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "theme/button.scss";
@import "theme/colors.scss";
@import "theme/dialog.scss";
@import "theme/fix.scss";
@import "theme/general.scss";
@import "theme/input.scss";
@import "theme/table.scss";

html,
body {
  height: 100%;
}

:root {
  //Slider circle
  --mdc-slider-active-track-color: #333;
  --mdc-slider-inactive-track-color: #333;
  --mdc-slider-handle-color: #333;
  --mdc-slider-focus-handle-color: #333;
  --mdc-slider-with-tick-marks-inactive-container-color: #333;
  --mat-slider-ripple-color: #333;
  //Slider bar
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 8px;
  --mat-slider-value-indicator-padding: 0 20px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 8px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-inactive-track-height: 9px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;

  //Checkbox
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: var(--color-text);
  --mdc-checkbox-selected-hover-icon-color: var(--color-text);
  --mdc-checkbox-selected-icon-color: var(--color-text);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-text);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-text);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-text);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-text);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;

  //Spinner
  --mdc-circular-progress-active-indicator-color: gray;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: var(--color-background);
  h1,
  h2,
  h3,
  h4,
  p,
  span {
    margin: 0;
    font-family: "Roboto", sans-serif;
    color: color("text");
  }
  button {
    border-radius: 16px;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
}

mat-tooltip-component {
  margin-bottom: -17px !important;
}

.mdc-tooltip__surface {
  z-index: 100;
  color: white !important;
}

.icone-instituicao {
  img {
    width: 32px !important;
    border-radius: 5px !important;
  }
}

dspe-card-dashboard:hover {
  .card-dashboard {
    filter: opacity(0.9) !important;
  }
}

p.dasboard-content {
  font-size: 2em;
  text-align: center;
  color: #6f6f6f;
  font-weight: bold;
}

dspe-button.full {
  button {
    min-width: 100%;
    text-align: center;
    justify-content: center;
  }
}
