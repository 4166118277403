input:focus,
textarea:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
