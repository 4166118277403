//Remover padding do modal
mat-dialog-content,
mat-list,
.mat-mdc-dialog-surface,
.mdc-dialog__surface {
  padding: 0 !important;
}

.maximize {
  .mat-mdc-dialog-surface {
    min-width: 85% !important;
    width: 85%;
    max-width: 85%;
  }
  .modal {
    min-width: 1000px !important;
    width: 85%;
    max-width: 85%;
  }
}

.maximize-no-padding {
  .mat-mdc-dialog-surface {
    min-width: 85% !important;
    width: 85%;
    max-width: 85%;
  }
  .modal {
    min-width: 1200px !important;
    width: 85%;
    max-width: 85%;
    .content {
      padding: 0 !important;
    }
  }
}
