.link:hover {
  cursor: pointer;
}
.radius {
  border-radius: 8px;
}
.radius-small {
  border-radius: 4px;
}
.radius-large {
  border-radius: 16px;
}
.shadow {
  box-shadow: 0px 4px 4px 0px #00000040;
}
.bold {
  font-weight: 700;
}
.italic {
  font-style: italic;
}
.border {
  border: 2px solid var(--color-dark);
}
.margin-right {
  margin-right: 1em;
}
.margin-top {
  margin-top: 1em;
}
