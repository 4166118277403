:root {
  --color-background: #f1f0ee;
  --color-light: #e9e9e9;
  --color-light2: #dedddd;
  --color-light3: #bababa;
  --color-primary: #d4d83c;
  --color-primary-hover: #b0bd19;
  --color-secondary: #282e54;
  --color-secondary-hover: #1a1f3f;
  --color-tertiary: #cd99fe;
  --color-tertiary-hover: #a47acb;
  --color-gray: #333452;
  --color-gray-hover: #111638;
  --color-success: #009482;
  --color-danger: #c01d23;
  --color-warning: #fec714;
  --color-info: #0080ff;
  --color-text: #2d3342;
  --color-text-light: #6c7a9d;
  --color-dark: #221f1f;
  --color-dark2: #1f1c1c;
  --color-dark3: #1b1919;
}
